(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name statistics.licenses.controller:StatsLicensesCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.statistics.licenses')
    .controller('StatsLicensesCtrl', StatsLicensesCtrl);

  function StatsLicensesCtrl(AclService,$state,$filter, $mdToast, LicenceStatistics, categories, genders, seasons,townHalls, currentSeason, licenceStats) {
    var vm = this;
    vm.ctrlName = 'StatsLicensesCtrl';
     if( AclService.isAdmin()){
        townHalls.unshift({id:9999, name:'Tots', email:'', phoneNumber:0});
    }
    var personGenders = _.filter(genders, 'person', true);
    vm.townhalls = angular.copy(townHalls);
     if (vm.townhalls.length === 1) {
      vm.selectedTownHall = vm.townhalls[0];
      $state.current.data.townHall = vm.selectedTownHall;
    } else {
      vm.selectedTownHall = ($state.current.data.townHall) ? $state.current.data.townHall : undefined;
    }
    vm.licenceStats = licenceStats;

    vm.phases = _.uniq(_.pluck(vm.licenceStats, 'phase'));
    vm.programmes = _.uniq(_.pluck(vm.licenceStats, 'programme'));
    vm.licenseTypes = _.uniq(_.pluck(vm.licenceStats, 'licenceType'));
    vm.seasons = seasons;

    vm.selectedPhase = undefined;
    vm.selectedProgramme = undefined;
    vm.selectedLicenseType = undefined;
    vm.selectedSeasonId = currentSeason.id;


    vm.myChartObject = {
      type: 'Table',
      displayed: false,
      data: {
        cols: buildCols(categories, personGenders),
        rows: buildRows(vm.licenceStats, categories, personGenders)
      },
      options: {
        title: $filter('translate')('STATISTICS.LICENSES'),
        cssClassNames : {'headerCell': 'header-padding'}
      },
      formatters: {}
    };

    vm.changeSeason = function () {
    //    alert(vm.selectedTownHall.id);
      LicenceStatistics.query({'season_id': vm.selectedSeasonId}, function (data) {
        vm.licenceStats = data;
        vm.changeFilter();
      }, function (err) {
        var message = err.data.message || 'ERROR_UPDATE';
        $mdToast.show(
          $mdToast.simple()
            .textContent($filter('translate')(message))
            .position('bottom left')
            .hideDelay(3000)
        );
      });
    };

    vm.changeFilter = function () {
      var filterParams = {};
      if (!_.isUndefined(vm.selectedPhase)) filterParams['phase'] = vm.selectedPhase;
      if (!_.isUndefined(vm.selectedProgramme)) filterParams['programme'] = vm.selectedProgramme;
      if (!_.isUndefined(vm.selectedLicenseType)) filterParams['licenceType'] = vm.selectedLicenseType;
      var filteredLicenceStats = _.filter(vm.licenceStats, filterParams);
      vm.myChartObject.data.rows = buildRows(filteredLicenceStats, categories, personGenders);
    };

    vm.readyHandler = function (chartWrapper) {
      vm.htmlTable = chartWrapper.getChart().getContainer().childNodes[0].childNodes[0].childNodes[0];
    };

    function buildCols(categories, personGenders) {
      var cols = [];

      cols.push({id: 'sport', label: $filter('translate')('SPORT'), type: 'string'});

      _.forEach(categories, function (c) {
        _.forEach(personGenders, function (g) {
          var l = $filter('translate')('CATEGORIES.' + c.name) + ' ' + $filter('translate')(g.name);
          cols.push({id: c.name + '_' + g.name, label: l, type: 'number'});
        })
      });

      cols.push({id: 'total', label: $filter('translate')('TOTAL'), type: 'number'});
      return cols;
    }

    function buildRows(data, categories, personGenders) {

      var rows = [];

      var sports = _.uniq(_.pluck(data, 'sport'));

      _.forEach(sports, function (s) {
        var sportName = $filter('translate')('SPORTS.' + s);
        var row = buildRow(sportName, categories, personGenders, _.filter(data, 'sport', s));
        rows.push(row);
      });
      var totalName = $filter('translate')('TOTAL');
      var totalRow = buildRow(totalName, categories, personGenders, data);

      rows.push(totalRow);
      return rows;

    }

    function buildRow(rowName, categories, personGenders, data) {
      var values = [];
      values.push({v: rowName});

      var total = 0;

      _.forEach(categories, function (c) {
        _.forEach(personGenders, function (g) {

          var predicate = {'category': c.name, 'gender': g.name};
          var licences = _.filter(data, predicate);
          var numLicenceIds = _.size(_.uniq(_.pluck(licences, 'licenceId')));

          values.push({v: numLicenceIds});
          total = total + numLicenceIds;
        })
      });
      values.push({v: total});
      return {c: values};
    }
  }
}());
